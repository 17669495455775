const intialState = {
    error: null,
    categories: [],
    loading: false,
    tables: [],
    userByid: [],
    isLodingUBI: false,
    // -----------------ROUND TABLE
    loadingCreateTable: false,

};

export const tableReducer = (state = intialState, action) => {
    switch (action.type) {

        case "START_FETCHING_ROUNDTABLE":
            return {
                ...state,
                loadingCreateTable: true,
            };
        case "CREATE_ROUNDTABLE_SUCCESS":
            return {
                ...state,
                loadingCreateTable: false,
            };
        case "GET_ALL_ROUNDTABLE":
            return {
                ...state,
                tables: action.data.filter((table) => !table.deleted),
                loadingCreateTable: false,
                error: false
            };
        case "DELETE_ROUNDTABLE":
            return {
                ...state,
                tables: state.tables.filter((table) => table.uid != action.data),
                loadingCreateTable: false,
                error: false
            };
        default:
            return state;
    }
};