const BASE_PATH = '/admin'


/**
 * //////////////////////////////////////////////////
 * GET routes
 * /////////////////////////////////////////////////
 */
 export const GET_MY_PROFILE = `${BASE_PATH}/my-profile` //GET
 

/**
 * ////////////////////////////////////////////////////
 * POST routes
 * ///////////////////////////////////////////////////
 */

export const LOGIN_API_URL = `${BASE_PATH}/login` //POST
export const GET_CATEGORIES_API_URL='/getCategoriesWeb'
export const ADD_MAINCATEGROY_API_URL='/addMainCategoriesWeb'
export const ADD_SUBCATEGROY_API_URL='/addSubCategoriesWeb'
export const DELETE_SUBCATEGROY_API_URL="/deleteSubCategoryWeb"
export const DELETE_CATEGROY_API_URL="/deleteMainCategoryWeb"
export const UPDATE_SUBCATEGORY_API_URL="/updateSubCategoryWeb"
export const GET_ALL_FORUMS_BY_PACKAGE="/getAllForumWeb"
export const GET_APP_INFO="/getAppInfo"
export const GET_UI_INFO="/appConfig"
export const CREATE_ROUND_TABLE="/createForaV2Web"
export const UPDATE_MAIN_CATEGORY_API_URL="/updateMainCategoryWeb"
export const UPDATE_USER_DETAILS_BY_ADMIN="/updateUserBySuperAdminWeb"
export const DELETE_USER_DETAILS_BY_ADMIN="/deleteUserBySuperAdmin"
export const GET_USER_DETAIL_BY_ID="/getUserByIdWeb"
export const SUSPEND_USER_BY_ADMIN="/suspendUser"
export const UNSUSPEND_USER_BY_ADMIN="/unsuspendUser"
export const CHANGE_ROLE="/changeUserRole"
export const FOLLOW_USER = `/toggleFollowerWeb`;
export const GET_USER_BY_ID = `/getUserByIdWeb`;
export const UPDATE_USER_DETAILS = `/updateUserWeb`;
