const intialState = {
  appData: [],
};
export const appReducers = (state = intialState, action) => {
  switch (action.type) {
    case "GET_APP_DETAILS":
      return {
        ...state,
        appData: action.data,
      };
    default:
      return state;
  }
};
