export const getCategorySponsoredUserCombined = (data) => {
  const sponsored = [];
  const category = [];
  const userCombined = [];
  data?.map((r) => {
    if (r.expand == "SPONSORED") sponsored.push(r);
    else if (r.expand == "CATEGORY") category.push(r);
    else if (r.expand == "USER_COMBINED") userCombined.push(r);
  });
  return {
    sponsored,
    category,
    userCombined,
  };
};

export const septateEachCategoriesForums = (data) => {
  const arr = [];
  data?.map((r) => r?.forum?.map((t) => arr.push(t)));
  return arr;
};

const monthName = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];
export const getDateAndTime = (d) => {
  var d1 = new Date(d),
    d = d1.getDate(),
    m = d1.getMonth(),
    y = d1.getFullYear();
  const dateString = monthName[m] + " " + d + " " + y;
  return dateString;
};

export const getTableStatus = (table) => {
  if (!table.next_recurrence) {
    return "expired";
  }
  const now = new Date().getTime();
  for (const recurrence of table.next_recurrence) {
    const start_time = new Date(recurrence.start).getTime();
    const end_time = new Date(recurrence.end).getTime();
    if (now >= start_time && now <= end_time) {
      return "active";
    } else if (now <= start_time) {
      return "future";
    }
  }
  return "expired";
};

export const getTableStatusString = (table) => {
  console.log(table)
  if (
    !table ||
    (!table.start_date &&
      !table.end_date &&
      !table.start_time &&
      !table.end_time &&
      !table.recurrence_time &&
      !table.recurrence)
  ) {
    return "Open Now";
  }

  const status = getTableStatus(table);
  const getTimeString = (table) => {
    if (table.start_time && table.end_time) {
      return ` between ${new Date(table.start_time).toLocaleTimeString([], {
        hour: "numeric",
        minute: "numeric",
        second: undefined,
        hour12: true,
      })} and ${new Date(table.end_time).toLocaleTimeString([], {
        hour: "numeric",
        minute: "numeric",
        second: undefined,
        hour12: true,
      })}`;
    } else if (table.start_time) {
      return ` from ${new Date(table.start_time).toLocaleTimeString([], {
        hour: "numeric",
        minute: "numeric",
        second: undefined,
        hour12: true,
      })}`;
    } else if (table.end_time) {
      return ` till ${new Date(table.end_time).toLocaleTimeString([], {
        hour: "numeric",
        minute: "numeric",
        second: undefined,
        hour12: true,
      })}`;
    }
    return ".";
  };
  const getStartTimeString = (table) => {
    if (
      status === "future" &&
      table.start_date &&
      new Date(table.start_date).getTime() > new Date().getTime()
    ) {
      return `Starts on ${new Date(table.start_date).toLocaleDateString([], {
        hour: "numeric",
        minute: "numeric",
        second: undefined,
        hour12: true,
      })}. `;
    }
    return "";
  };
  const getEndTimeString = (table) => {
    if (status === "active" && table.end_date) {
      return ` Ends on ${new Date(table.end_date).toLocaleDateString([], {
        hour: "numeric",
        minute: "numeric",
        second: undefined,
        hour12: true,
      })}`;
    } else if (status === "expired" && table.end_date) {
      return `Ended on ${new Date(table.end_date).toLocaleDateString([], {
        hour: "numeric",
        minute: "numeric",
        second: undefined,
        hour12: true,
      })}`;
    }
    return "";
  };

  const getDayNameFromIndex = (index) => {
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][index % 7];
  };
  let ret = getStartTimeString(table);
  if (!table.recurrence || table.recurrence === "daily") {
    ret += "Daily";
  } else if (table.recurrence === "monthly") {
    ret += `Every month on ${new Date(
      table.start_date || table.created_on
    ).getDate()}`;
  } else if (table.recurrence === "weekly") {
    if (
      !table.recurrence_time ||
      !Array.isArray(table.recurrence_time) ||
      table.recurrence_time.length !== 1
    ) {
      ret += `Weekly`;
    } else {
      ret += `Every week on ${getDayNameFromIndex(table.recurrence_time[0])}`;
    }
  } else if (table.recurrence === "fortnightly") {
    ret += `Fortnightly next on ${new Date(
      table?.next_recurrence[0]?.start
    ).toLocaleString()}`;
  } else if (table.recurrence === "custom") {
    ret += `Every week on ${table.recurrence_time
      ?.map((entry) => getDayNameFromIndex(entry))
      .join(", ")
      .replace(/,(?!.*,)/gim, " and")}`;
  } else {
    return "";
  }
  ret += getTimeString(table) + getEndTimeString(table);
  return ret;
};

export const getPackageName = (v) => {
  if (v.package == "com.seesurround.roundtables")
    return { ...v, name: "Chat Fora" };
  if (v.package == "com.seesurround.lecol") return { ...v, name: "Le Col" };
  if (v.package == "com.seesurround.engfanzone")
    return { ...v, name: "England Fan Zone" };
};
