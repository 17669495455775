import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { categoryReducer } from "./reducer/categoryReducer";
import { tableReducer } from "./reducer/tableReducer";
import { userReducer } from "./reducer/userReducer";
import { userPackageReducer } from "./reducer/userPackageReducer";
import { appReducers } from "./reducer/appReducers";
import { userProfileReducer } from "./reducer/userProfileReducer";
import { changeThemeReducer } from "./reducer/changeThemeReducer";

const appReducer = combineReducers({
    category: categoryReducer,
    user: userReducer,
    userAfterLoginPackageReducer: userPackageReducer,
    userInfo:userProfileReducer,
    table:tableReducer,
    appReducers:appReducers,
    changeThemeReducer: changeThemeReducer,

});

export const store = createStore(
  appReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
