const intialState = {
    categoryData: [],
    categoryCount: 0,
    error: null,
    loading: false,
}

export const categoryReducer = (state = intialState, action) => {
    var count = 0
    for (let i = 0; i < action.data?.length; i++) {
        let data = action.data[i]
        for (let j = 0; j < data?.children?.length; j++) {
            count++
        }
    }
 
    switch (action.type) {
        case "START_LOADING":
            return {
                ...state,
                loading: true,
            };
        case "CATEGORY_SUCCESS":

            return {
                ...state,
                categoryData: action.data,
                categoryCount: count,
                loading: false,
                error: null,
            };

        case "CATEGORY_FAILED":
            return {
                ...state,
                error: action.error,
                loading: true,
            };
        default:
            return state;
    }

}