const intialState = {
    error: null,
    afterLoginPackageName: {},
    loading: false,
};

export const userPackageReducer = (state = intialState, action) => {
    switch (action.type) {
        case "START_LOADING":
            return {
                ...state,
                loading: true,
            };
        case "USER_LOGIN_SUCCESS_PACKAGE_NAME":
            return {
                ...state,
                afterLoginPackageName: action.data,
                loading: false,
                error: null,
            };
        case "PACKAGE_FAILED":
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        default:
            return state;
    }
};