import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/style.scss';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'context/AuthProvider';
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { store} from "./store/store";
const App = React.lazy(()=> import("App"))
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
