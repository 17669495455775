import { THEME_DARK_CHANGE, THEME_LIGHT_CHANGE } from "store/action/themeActionType";

const light = {
        "logo": "https://storage.googleapis.com/see-surround.appspot.com/logos/fora%20logo.png",
        "background": "#FEFEFE",
        "tab-bar": "#E8EBEC",
        "primary": "#0583F2",
        "secondary": "#E8EBEC",
        "text-primary": "#080808",
        "text-secondary": "#636363"
}

const intialState = {
    themeDataType: light,
  };
  export const changeThemeReducer = (state = intialState, action) => {
    switch (action.type) {
      case THEME_DARK_CHANGE:
        return {
          ...state,
          themeDataType: action.payload
        };
        // case THEME_LIGHT_CHANGE:
        // return {
        //   ...state,
        //   dark: {
        //     a: "20",
        //   },
        // };
      default:
        return state;
    }
  };
  