const intialState = {
    userData: [],
    subAdminData: [],
    userCount: 0,
    error: null,
    loading: false,
    testdata: {},
    loginPackageName: {},
    loginAuthData: {},
}

export const userReducer = (state = intialState, action) => {
    switch (action.type) {
        case "START_LOADING":
            return {
                ...state,
                loading: true,
            };
        case "STOP_LOADING":
            return {
                ...state,
                loading: false,
            };
        case "GET_USER_DETAILS_SUCCESS":
            return {
                ...state,
                loading: false,
            };
        case "USER_FAILED":
            return {
                ...state,
                error: action.error,
                loading: true,
            };
        
        case "USER_SUCCESS2":
                state.testdata = action.data
                return {
                    ...state,
                  testdata: state.testdata,
                }

        case "USER_LOGIN_SUCCESS":
                state.loginPackageName = action.data
                return {
                    ...state,
                    loginPackageName: state.loginPackageName,
                }

        case "USER_SUCCESS":
            state.userData = action.data
            state.subAdminData = action.data.filter((user) => (user.user_type == "admin" || user.user_type == "superAdmin"))
            return {
                ...state,
                userData: state.userData,
                subAdminData: state.subAdminData,
                userCount: state.userData.filter((user) => user.name != undefined).length,
                loading: false,
                error: null,
            };

        case "ADD_NEW_USER_SUCCESS":
            console.log(action.data)
            let idt = action.data.uid
            delete action.data["uid"]
            console.log(action.data)
            action.data["id"] = idt
            state.userData.push(action.data)
            if (action.isAdmin) {
                state.subAdminData.push(action.data)
                console.log(state.subAdminData)
            }
            return {
                ...state,
                userData: state.userData,
                userCount: state.userData.length,
                loading: false,
                error: null,
            };
        case "UPDATE_USER_DETAIL_SUCCESS":
            console.log(action.data)
            let id
            if (action.data.uid) {
                id = action.data.uid
            } else {
                id = action.data.id
            }
            state.userData = state.userData.filter((user) => user.id != action.data.uid)
            let sp = state.subAdminData.filter((user) => user.id == action.data.uid)
            delete action.data["uid"]
            action.data["id"] = id
            state.userData.push(action.data)

            console.log(sp, "<---sp.")
            if (sp.length > 0) {
                state.subAdminData = state.subAdminData.filter((user) => user.id != action.data.id)
                state.subAdminData.push(action.data)
                console.log(state.subAdminData)
            }

            return {
                ...state,
                userData: state.userData,
                subAdminData: state.subAdminData,
                userCount: state.userData.length,
                loading: false,
                error: null,
            };
        case "DELETE_USER_DETAIL_SUCCESS":

            state.userData = state.userData.filter((user) => user.id != action.data)
            state.subAdminData = state.subAdminData.filter((user) => user.id != action.data)
            return {
                ...state,
                userData: state.userData,
                subAdminData: state.subAdminData,
                userCount: state.userData.length,
                loading: false,
                error: null,
            };
            case "SUSPEND_USER_SUCCESS":
               
             let updatedData={
                suspended:action.data.issuspended
             }
              const indexToUpdate = state.userData.findIndex(user => user.id === action.data.uid.userId);
              if (indexToUpdate !== -1) {
                    // Update the user object at the found index
                    state.userData[indexToUpdate] = {
                      ...state.userData[indexToUpdate],
                      ...updatedData, // Update with the provided data
                    };
                  }
                return {
                    ...state,
                    userData: state.userData,
                    subAdminData: state.subAdminData,
                    userCount: state.userData.length,
                    loading: false,
                    error: null,
                };
        default:
            return state;
    }

}