import React, { createContext, useState, useEffect, lazy } from "react";

import jwt_decode from "jwt-decode";
import axiosInstance, { setPackageHeaderId } from "utils/axios";
import { getPackageName } from "utils/utils";
import { CallWithAuth } from "action/apiActions";
import { GET_MY_PROFILE, GET_USER_BY_ID } from "action/apiPath";
import { toast } from "react-toastify";

const FullPageLoader = lazy(() => import("components/FullPageLoader"));
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    isInitialized: false,
    roles: [],
    userData: {},
    userCount: 0,
    fullPackageInfo: [],
    categroiesCount: 0,
    roundTablesCount: 0,
    theme: {},
    darkTheme: false,
  });

  const [permissionPackages, setpermissionPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");

  const [isAllDataField, setIsAllDataField] = useState({
    loading: true,
    status: false,
    data: null,
  });

  const logout = (showMsg = true) => {
    delete axiosInstance.defaults.headers.common.Authorization;
    // localStorage.clear();
    localStorage.removeItem("userInfo");
    localStorage.removeItem("accessToken");
    setAuth({
      isAuthenticated: false,
      isInitialized: false,
      roles: "",
      userData: {},
      fullPackageInfo: [],
      //test: {},
    });
    if (showMsg) toast.success("User logout Successfully");
  };
  const isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwt_decode(accessToken);
    const currentTime = Date.now() / 1000;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    return decoded.exp > Math.ceil(currentTime);
  };

  const checkIfAllDataFilled = async () => {
    try {
      let response = await CallWithAuth("POST", GET_USER_BY_ID, { data: {} });

      if (response.res.status === 401) {
        localStorage.removeItem("accessToken");
        return;
      }

      if (response.res.status === 200) {
        setIsAllDataField((prev) => ({
          data: response?.res?.data?.result?.data,
          loading: false,
          status: true,
        }));
        let rowData = [];
        if (response?.res?.data?.result?.data?.sub_package) {
          rowData = JSON.parse(
            JSON.stringify(response?.res?.data?.result?.data?.sub_package)
          );
        }
        const packages = rowData.map((v) => {
          return getPackageName(v);
        });
        // console.log("here-here", packages, rowData);
        setPackageHeaderId(packages[0].package);
        setpermissionPackages(packages);
        setSelectedPackage(JSON.parse(JSON.stringify(packages[0])));
        // getPackageName()
      } else {
        setIsAllDataField((prev) => ({
          data: {},
          loading: false,
          status: false,
        }));
        return;
      }
    } catch (err) {
      setIsAllDataField((prev) => ({
        data: {},
        loading: false,
        status: false,
      }));
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      isValidToken(localStorage.getItem("accessToken"))
    ) {
      setAuth({
        isAuthenticated: true,
        isInitialized: true,
        userData: {},
        roles: [],
        packageId: "",
      });
    } else {
      setAuth({
        isAuthenticated: false,
        isInitialized: true,
        roles: "",
        userData: {},
      });
    }
  }, []);

  const onAuthChange = async (auth) => {
    if (
      localStorage.getItem("accessToken") &&
      isValidToken(localStorage.getItem("accessToken"))
    ) {
      checkIfAllDataFilled();
    }
  };

  useEffect(() => {
    onAuthChange(auth);
  }, [auth]);

  if (!auth.isInitialized) {
    return <FullPageLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setIsAllDataField,
        isAllDataField,
        setAuth,
        logout,
        permissionPackages,
        selectedPackage,
        setSelectedPackage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
