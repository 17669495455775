import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to set the dynamic package ID
export const setPackageHeaderId = (packageId) => {
  axiosInstance.defaults.headers.common["x-package-id"] = packageId;
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log("interceptor request sent config: \n", config);
    return config;
  },
  function (error) {
    // console.log("error in request interceptor:--\n", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // console.log("interceptor response get config: \n", response);
    return response;
  },
  function (error) {
    // console.log("error in response interceptors:--\n", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
